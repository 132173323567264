import * as React from "react"
import { useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Button from "./Button"
import {isMobile} from 'react-device-detect';

import { graphql } from 'gatsby'
import '../styles/components/HomeBanner.css';
import "aos/dist/aos.css"

const appHeight = () => {
    const doc = document.documentElement
    if (isMobile) {
      doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    }else{
      doc.style.setProperty('--app-height', `100vh`)
    }

    console.log('here') 
}

if(typeof window !== "undefined"){
  window.addEventListener('load', appHeight)
  appHeight()
}



const HomeBanner = () => 

{
  const data = useStaticQuery(graphql`
  query HomeBanner {
    allPrismicHomePage{
      edges{
        node{
          data{
            hero_title{
              text
            }
            hero_image {
              thumbnails
              localFile{
                childImageSharp{
                  gatsbyImageData(
                    width: 2000
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              fluid{
                ...GatsbyPrismicImageFluid
              }
            }
            button_text
            button_link{
              url
            }
          }
        }
     }
   }
  }
  `)
  
  const document = data.allPrismicHomePage.edges[0].node.data

  return (
    <>
      <div className="bannerContainer">
        <GatsbyImage image={ getImage(document.hero_image.localFile.childImageSharp.gatsbyImageData) } alt="hello" className="bannerImage" />
        <div className="overlayContainer">
          <div className="textContainer" data-aos="fade-right">
            <h2 data-aos="fade-right">We are . . .</h2>
            <h1 className="titleText">             
              {document.hero_title.text}
            </h1>
            <Button link="/" anchor="banner-bottom">Learn More</Button>
          </div>
          
        </div>
      </div>
      <div id="banner-bottom" />
    </>
    
  )
}

export default HomeBanner
